import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import type { MetaFunction, LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import type { Theme } from '#app/utils/hooks/use-theme'
import {
  Form,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useNavigation,
  useRouteError,
} from '@remix-run/react'
import { json } from '@remix-run/node'
import { useChangeLanguage } from 'remix-i18next/react'
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { authenticator } from '#app/modules/auth/auth.server'
import { useNonce } from '#app/utils/hooks/use-nonce'
import { getHints } from '#app/utils/hooks/use-hints'
import { prisma } from '#app/utils/db.server'
import { getTheme, useTheme } from '#app/utils/hooks/use-theme'
import { getToastSession } from '#app/utils/toast.server'
import { csrf } from '#app/utils/csrf.server'
import { honeypot } from '#app/utils/honeypot.server'
import { combineHeaders, getDomainUrl } from '#app/utils/misc.server'
import { siteConfig } from '#app/utils/constants/brand'
import { useToast } from '#app/components/toaster'
import { Toaster } from '#app/components/ui/sonner'
import { ClientHintCheck } from '#app/components/misc/client-hints'
import { GenericErrorBoundary } from '#app/components/misc/error-boundary'
import i18nServer, { localeCookie } from '#app/modules/i18n/i18n.server'
// Supports weights 100-900
import '@fontsource-variable/dm-sans/wght.css'
import RootCSS from './root.css?url'
import soonerStyles from './styles/sooner.css?url'
import { useEffect, useState } from 'react'
import posthog from 'posthog-js'
import { languageCookie } from './routes/resources+/cookies.server'
import { useTranslation } from 'react-i18next'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './components/ui/alert-dialog'
import { Button } from './components/ui/button'

export const handle = { i18n: ['translation'] }

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? `${siteConfig.siteTitle}` : `Error | ${siteConfig.siteTitle}` },
    {
      name: 'description',
      content: siteConfig.siteDescription,
    },
  ]
}

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: RootCSS },
    { rel: 'stylesheet', href: soonerStyles },
    { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
  ]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const sessionUser = await authenticator.isAuthenticated(request)
  const user = sessionUser?.id
    ? await prisma.user.findUnique({
        where: { id: sessionUser?.id },
        include: {
          image: { select: { id: true } },
          roles: { select: { name: true } },
        },
      })
    : null

  const locale = await i18nServer.getLocale(request)
  const cookies = request.headers.get('Cookie')
  const preferredLanguage = await languageCookie.parse(cookies)
  const { toast, headers: toastHeaders } = await getToastSession(request)
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken()

  return json(
    {
      user,
      locale,
      toast,
      csrfToken,
      honeypotProps: honeypot.getInputProps(),
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: { theme: getTheme(request) },
      },
      preferredLanguage,
    } as const,
    {
      headers: combineHeaders(
        { 'Set-Cookie': await localeCookie.serialize(locale) },
        toastHeaders,
        csrfCookieHeader ? { 'Set-Cookie': csrfCookieHeader } : null,
      ),
    },
  )
}

function Document({
  children,
  nonce,
  lang = 'de',
  dir = 'ltr',
  theme = 'light',
  preferredLanguage,
}: {
  children: React.ReactNode
  nonce: string
  lang?: string
  dir?: 'ltr' | 'rtl'
  theme?: Theme
  preferredLanguage?: string
}) {
  const matches = useMatches()
  const match = matches.find((match) => match.data && match.data.canonicalUrl)
  const canonicalUrl = match?.data.canonicalUrl as string
  const [showPopup, setShowPopup] = useState(false)
  const location = useLocation()
  const { i18n, t } = useTranslation()
  useEffect(() => {
    if (!preferredLanguage && location.pathname === '/') {
      setShowPopup(true)
    } else {
      i18n.changeLanguage(preferredLanguage)
    }
  }, [preferredLanguage, i18n, location.pathname])

  return (
    <html
      lang={lang}
      dir={dir}
      className={`${theme} overflow-x-hidden`}
      style={{ colorScheme: theme }}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!!canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <Links />
      </head>
      <body className="h-auto w-full">
        {children}
        <AlertDialog open={showPopup} onOpenChange={setShowPopup}>
          <AlertDialogContent className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <AlertDialogHeader>
              <AlertDialogTitle>{t('Choose your preferred language')}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('Select the language you would like to use for this application.')}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex items-center justify-center gap-4">
              <Form method="post" action="/resources/set-language">
                <input type="hidden" name="language" value="en" />
                <Button
                  onClick={() => {
                    setShowPopup(false)
                  }}
                  type="submit">
                  {t('Continue in English')}
                </Button>
              </Form>
              <Form method="post" action="/resources/set-language">
                <input type="hidden" name="language" value="de" />
                <Button
                  onClick={() => {
                    setShowPopup(false)
                  }}
                  type="submit">
                  {t('Switch to German')}
                </Button>
              </Form>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <Toaster closeButton position="top-center" theme={theme} />
      </body>
    </html>
  )
}

function AppWithProviders() {
  const { locale, preferredLanguage, user, toast, csrfToken, honeypotProps } =
    useLoaderData<typeof loader>()

  const nonce = useNonce()
  const location = useLocation()
  // Updates the i18n instance language.
  useChangeLanguage(preferredLanguage ?? locale)

  // Renders toast (if any).
  useToast(toast)

  useEffect(() => {
    // identify users for posthog if they are logged in
    if (!user) return
    posthog.identify(
      user.id,
      { email: user?.email, name: user?.username }, // optional: set additional user properties
    )
  }, [location, user])
  return (
    <Document
      preferredLanguage={preferredLanguage}
      nonce={nonce}
      theme="light"
      lang={locale ?? 'de'}>
      <AuthenticityTokenProvider token={csrfToken}>
        <HoneypotProvider {...honeypotProps}>
          <Outlet />
        </HoneypotProvider>
      </AuthenticityTokenProvider>
    </Document>
  )
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
  const error = useRouteError()
  const nonce = useNonce()
  const theme = useTheme()

  captureRemixErrorBoundaryError(error)

  return (
    <Document nonce={nonce} theme={theme}>
      <GenericErrorBoundary
        statusHandlers={{
          403: ({ error }) => (
            <p>You are not allowed to do that: {error?.data.message}</p>
          ),
        }}
      />
    </Document>
  )
}
